
.header {
    background-color: #101F33;
    color: white;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .menu {
    display: flex;
}

.header .menu a,
.header .logo a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.header .logo {
    flex-grow: 0;
}

.header .logo a {
    display: flex;
    align-items: center;
}

.header .logo i {
    margin-right: 10px;
}

.main {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
}

.leftPanel {
    flex: 3;
    padding: 40px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.rightPanel {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    background-color: #f4f4f4;
}

.leftPanel .title {
    font-size: 4em;
    margin-bottom: 0.25em;
    font-weight: 500;
    color: #007bff;
}

.leftPanel .message {
    font-size: 1.5em;
    color: #333;
}

.login form {
    display: flex;
    flex-direction: column;
}

.login form input,
.login form button {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
}

.login form button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.login a {
    display: block;
    background-color: #db4437;
    color: white;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    margin-top: 10px;
}
.topmenus {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}
.itemLeft {
    margin-right: auto;
}
.emph {
    font-size: 1.5em;
    font-weight: bold;
    color: #515755;
}
